
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















.content {
  .row {
    .col {
      @include liquid('margin-bottom', 50px, 120px);

      width: 100%;

      @include mq($from: m) {
        width: 50%;
      }
    }

    .content__text-container {
      @include mq($from: m) {
        padding-left: 50px;
      }
    }
  }
}

.content__visual::v-deep {
  width: 100%;
}
