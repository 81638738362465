
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































.extra-details {
  @include liquid('padding-top', 50px, 110px);
  @include liquid('padding-bottom', 50px, 110px);

  border-top: 1px currentColor solid;
}

.extra-details__left-column {
  @include liquid('margin-bottom', 40px, 80px);

  @include mq($from: m) {
    margin-bottom: 0;
  }
}

.extra-details__item {
  &:not(:last-child) {
    @include liquid('margin-bottom', 40px, 80px);
  }
}

.extra-details__item__title {
  @include liquid('font-size', 20px, 28px);
  @include liquid('margin-bottom', 18px, 26px);

  line-height: 1.2;
}

.extra-details__item__content {
  @include liquid('font-size', 18px, 24px);

  line-height: 1.5;

  // Override wysiwyg rule
  ::v-deep {
    p {
      margin-bottom: 0;
    }
  }
}
