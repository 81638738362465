
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































::v-deep {
  .gallery__main-visual {
    width: auto;
    max-height: 95vh;
    max-height: calc(var(--vh, 1vh) * 95);
    margin-right: auto;
    margin-left: auto;
    background-color: darken($color: $c-surface-light, $amount: 10%);
  }
}

.gallery {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include mq($until: l) {
    .figure__caption {
      margin-top: 1rem;
    }
  }

  @include mq($from: l) {
    flex-direction: row;
    align-items: initial;
  }
}

.gallery__thumbnail-list {
  display: flex;
  margin-top: 2rem;

  @include mq($from: l) {
    margin-top: 0;
    flex-direction: column;
    flex-shrink: 0;
    width: 20%;
    margin-right: 2.4rem;
  }
}

.gallery__thumbnail {
  max-width: 13.6rem;

  &:not(:last-of-type) {
    margin-right: 2.4rem;

    @include mq($from: l) {
      margin-right: 0;
      margin-bottom: 2.4rem;
    }
  }
}

.gallery__thumbnail__button {
  display: block;
  padding: 0;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
}
