@mixin center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin get-all-space {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin image-fit($fit: cover, $position: null) {
  @include get-all-space;

  object-fit: $fit;

  @if $position {
    object-position: $position;
  }
}

@mixin owl($margin: false) {
  > :first-child {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }
}

// https://www.bronco.co.uk/our-ideas/creating-a-clamp-fallback-function-in-sass-scss/
@mixin clamp(
  $property,
  $min-size,
  $scaler,
  $max-size,
  $min-size-left: false,
  $scaler-left: false,
  $max-size-left: false
) {
  @if $min-size-left == false {
    #{$property}: $max-size;
    #{$property}: #{'min(max(#{$min-size}, #{$scaler}), #{$max-size})'};
    #{$property}: clamp(#{$min-size}, #{$scaler}, #{$max-size});
  } @else if $min-size-left == 0 or $min-size-left == auto {
    #{$property}: $max-size $min-size-left;
    #{$property}: #{'min(max(#{$min-size}, #{$scaler}), #{$max-size})'}
      $min-size-left;
    #{$property}: clamp(#{$min-size}, #{$scaler}, #{$max-size}) $min-size-left;
  } @else {
    #{$property}: $max-size $min-size-left;
    #{$property}: #{'min(max(#{$min-size}, #{$scaler}), #{$max-size})'}
      #{'min(max(#{$min-size-left}, #{$scaler-left}), #{$max-size-left})'};
    #{$property}: clamp(#{$min-size}, #{$scaler}, #{$max-size})
      clamp(#{$min-size-left}, #{$scaler-left}, #{$max-size-left});
  }
}

@mixin liquid($property, $min, $max) {
  $scaler: #{strip-unit($max) * 100 / strip-unit($content-width) + 'vw'};
  @include clamp($property, $min, $scaler, $max);
}

@mixin fluid($property, $map) {
  $finalmap: ();

  @each $breakpoint, $value in $map {
    $nameToPx: map-get($mq-breakpoints, $breakpoint);

    @if $nameToPx {
      $finalmap: map-merge(
        $finalmap,
        (
          $nameToPx: $value,
        )
      );
    } @else {
      $finalmap: map-merge(
        $finalmap,
        (
          $breakpoint: $value,
        )
      );
    }
  }

  @include poly-fluid-sizing($property, $finalmap);
}
